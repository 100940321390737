import React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'

const Wrapper = styled.div`

  border: 4px solid ${(props) => props.theme.colors.secondary};
  border-radius: 5px;
  margin: 0 auto 2em;
  padding: 0.25em;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  transition: background 0.2s;
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    flex: ${(props) => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 auto 1em;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    flex: ${(props) => (props.featured ? '0 0 100%' : '0 0 32%')};
  }

  a {
  
    margin: auto;
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
    transition: 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: auto;
  }

  h1 {
    font-size: 1.5em;
    padding: 0.5em 0.5em 1em;
  }
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1em;
  }

`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 75%;
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.text};
    }
  }
`

const Aboutus = ({ data }) => {
  return (
    <Layout>
      <SEO title="About Us" description="About Us" />
      <Container>
        <Wrapper>

          <PageTitle>About this site</PageTitle>
          <div>This site is a hand curated grouping of products I've found recommended by various forums, groups and blogs. I find that the best products aren't necessarily ones found by professional review sites. They're usually ones that enthusiast groups recommend after much trial and error. I hope this site is helpful to you, let me know if there's something missing by sending me an email, <a href="mailto:chris@crowdrecs.com">chris@crowdrecs.com</a> </div>
          <br /><div>This site using the following code as a basis</div>
          <List>
            <Item>
              <a
                href="https://www.contentful.com/"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                <img
                  src="https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg"
                  style={{ width: '100px' }}
                  alt="Powered by Contentful"
                />
              </a>
            </Item>
            <Item>
              <a
                href="https://github.com/ryanwiemer/gatsby-starter-gcn"
                target="_blank"
                rel="noopener noreferrer"
              >
                gatsby-starter-gcn
              </a>{' '}
              by{' '}
              <a
                href="https://github.com/ryanwiemer"
                target="_blank"
                rel="noopener noreferrer"
              >
                @ryanwiemer
              </a>
            </Item>
            <Item>
              <a href="https://www.flaticon.com/free-icons/star" title="star icons">Star icons created by Freepik - Flaticon</a>
              </Item>
          </List>

        </Wrapper>
      </Container>
    </Layout>
  )
}

export default Aboutus
